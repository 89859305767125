<template>
  <v-container fluid>
    <ApolloQuery
      :query="require('@/graphql/variables/variableSet.gql')"
      :variables="{ id: variable_set_id, garbage: rando }"
    >
      <template v-slot="{ result: { loading, error, data }, isLoading }">
        <v-progress-linear indeterminate v-if="isLoading" />
        <h3 v-if="!isLoading && data">{{ data.variableSet.name }} Variables</h3>
        <p
          v-if="
            !isLoading &&
              data.variableSet.description !== '' &&
              data.variableSet.instructions !== ''
          "
        >
          <span v-if="!isLoading && data.variableSet.description !== ''">
            <strong>Description:</strong><br />{{ data.variableSet.description
            }}<br />
          </span>
          <span v-if="!isLoading && data.variableSet.instructions !== ''">
            <strong>Instructions:</strong><br />{{
              data.variableSet.instructions
            }}
          </span>
        </p>
        <v-data-table
          v-if="data && !loading"
          v-model="selected"
          :items="data.variableSet.variables"
          :headers="headers"
          item-key="id"
          :search="keywords"
          :items-per-page.sync="itemsPerPage"
          show-select
          :footer-props="{ itemsPerPageOptions }"
        >
          <template v-slot:item.action="{ item }">
            <v-icon class="mr-2" @click="editVariable(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="promptForDelete(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <v-alert type="error" v-else-if="error">
          There was an error loading data. The server said...<br />
          <strong>{{ error }}</strong>
        </v-alert>
      </template>
    </ApolloQuery>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="320"
      v-if="selectedVariable.name !== ''"
    >
      <v-card>
        <v-card-title class="headline">Delete Variable?</v-card-title>
        <v-card-text>
          <p>
            You are about to delete the variable
            <strong>{{ selectedVariable.name }}</strong
            >. This cannot be undone. Are you sure you want to delete this
            variable?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteDialog = false">{{
            $t("cancel")
          }}</v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="deleteVariable(selectedUser)"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "VariableTable",
  components: {
    // "user-card": UserCard
  },
  props: {
    keywords: {
      type: String,
      required: true
    },
    reload: {
      type: Number,
      default: 0
    },
    variable_set_id: {
      type: String
    }
  },
  data: () => ({
    itemsPerPageOptions: [25, 50, 100],
    itemsPerPage: 25,
    deleteDialog: false,
    selectedVariable: {},
    selected: [],
    rando: 0,
    headers: [
      {
        text: "Name",
        align: "left",
        value: "name"
      },
      { text: "Comment", value: "comment" },
      { text: "Definition", value: "definition" },
      { text: "Evaluated Definition", value: "evaluated_definition" },
      { text: "Sort Order", value: "sort_order" },
      { text: "Actions", align: "right", sortable: false, value: "action" }
    ]
  }),
  watch: {
    reload: function(val) {
      this.rando = val;
    }
  },
  methods: {
    promptForDelete(variable) {
      this.selectedVariable = variable;
      this.deleteDialog = true;
    },
    deleteVariable(variable) {
      this.$apollo
        .mutate({
          // Query
          mutation: require("@/graphql/variables/deleteVariable.gql"),
          // Parameters
          variables: {
            id: variable.id
          }
        })
        .then(data => {
          // report success
          this.$emit("variable-form-closed", data);
          this.rando++;
        })
        .catch(error => {
          // Error
          console.error(error);
          // update user....
        });
      this.deleteDialog = false;
    },
    editVariable(variable) {
      variable.variable_set = { id: this.variable_set_id };
      this.$emit("edit-variable", variable);
    }
  }
};
</script>
