<template>
  <v-card>
    <v-card-title>
      <h3>Flare Integrations</h3>
      <v-spacer />
      <v-text-field
        v-model="keywords"
        append-icon="mdi-magnify"
        label="Search..."
        clearable
        single-line
        hide-details
      />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" icon fab>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>New Flare Variable Set</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <variable-table
        :keywords.sync="keywords"
        :variable_set_id.sync="variable_set_id"
        :reload.sync="reloadVar"
        @edit-variable="editVariable"
      />

      <v-dialog v-model="variableFormDialog" persistent max-width="600">
        <variable-form
          :variable.sync="formVariable"
          @variable-form-saved="
            closeVariableForm();
            reloadGrid();
          "
          @variable-form-closed="closeVariableForm()"
        />
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import VariableTable from "@/views/variables/VariableTable";
import VariableForm from "@/views/variables/VariableForm";

export default {
  name: "variables",
  components: {
    "variable-table": VariableTable,
    "variable-form": VariableForm
  },
  props: ["variable_set_id"],
  data: () => ({
    keywords: "",
    reloadVar: 0,
    formVariable: {},
    selectedVariable: {},
    variableFormDialog: false
  }),
  methods: {
    reloadGrid: function() {
      this.reloadVar++;
    },
    editVariable: function(v) {
      this.formVariable = v;
      this.variableFormDialog = true;
    },
    closeVariableForm: function() {
      this.variableFormDialog = false;
    }
  }
};
</script>
