<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit, validate, reset, invalid }"
  >
    <v-form>
      <v-card>
        <v-card-title>
          <span class="headline">Variable Set Form</span>
        </v-card-title>
        <v-card-text>
          <v-progress-linear v-if="formLoading" indeterminate />
          <v-container>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="SetName"
                  rules="required"
                >
                  <v-text-field
                    :error-messages="errors"
                    data-vv-name="SetName"
                    v-model="formData.name"
                    :label="$t('name')"
                    :disabled="formLoading"
                    required
                  />
                </ValidationProvider>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="formData.description"
                  :label="$t('description')"
                  :disabled="formLoading"
                  required
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="formData.instructions"
                  :label="$t('instructions')"
                  :disabled="formLoading"
                  required
                />
              </v-col>

              <v-col cols="12" sm="6">
                <ApolloQuery
                  :query="require('@/graphql/client/clientsDropdown.gql')"
                >
                  <template v-slot="{ result: { loading, error, data } }">
                    <ValidationProvider
                      v-if="data"
                      v-slot="{ errors }"
                      name="Client"
                      rules="required"
                    >
                      <v-select
                        :items="data.allClients"
                        data-vv-name="Client"
                        :error-messages="errors"
                        :disabled="formLoading"
                        :loading="formLoading"
                        item-text="name"
                        item-value="id"
                        v-model="client_id"
                        label="Client"
                        clearable
                        required
                      />
                    </ValidationProvider>
                    <v-select v-else label="Loading Clients" />
                  </template>
                </ApolloQuery>
              </v-col>

              <v-col cols="12" sm="6">
                <ApolloQuery
                  :query="require('@/graphql/project/projectsDropdown.gql')"
                  :variables="{ client_id: formData.client_id }"
                >
                  <template v-slot="{ result: { loading, error, data } }">
                    <v-select v-if="loading" label="Loading..." />
                    <ValidationProvider
                      v-else-if="data"
                      v-slot="{ errors }"
                      name="Project"
                      rules="required"
                    >
                      <v-select
                        data-vv-name="Project"
                        :error-messages="errors"
                        :items="data.allProjects"
                        :disabled="formLoading"
                        :loading="loading"
                        item-text="name"
                        item-value="id"
                        v-model="project_id"
                        :label="$t('project')"
                        clearable
                        required
                      />
                    </ValidationProvider>
                  </template>
                </ApolloQuery>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="$emit('variable-set-form-closed')"
            :disabled="formLoading"
            >{{ $t("close") }}</v-btn
          >
          <v-btn
            v-if="formData.id"
            color="primary darken-1"
            text
            :loading="formLoading"
            :disabled="formLoading"
            @click="handleSubmit(updateVariableSet())"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            v-else
            color="primary darken-1"
            text
            :disabled="formLoading"
            :loading="formLoading"
            @click="handleSubmit(createVariableSet())"
            >{{ $t("create") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, setInteractionMode } from "vee-validate";
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty"
});
export default {
  name: "VariableSetForm",
  props: {
    variable_set: {
      type: Object,
      default: () => ({
        id: "",
        name: "",
        filename: "",
        instructions: "",
        client: "",
        project: ""
      })
    }
  },
  data: () => ({
    error: null,
    mutation: "",
    formLoading: false,
    defaultForm: {
      id: "",
      name: "",
      description: "",
      instructions: "",
      client: { id: "" },
      project: { id: "" }
    },
    formData: {}
  }),
  created() {
    this.formData = { ...this.defaultForm, ...this.variable_set };
  },
  computed: {
    client_id: {
      // this feels wrong, but it works...
      // getter
      get: function() {
        if (this.variable_set.client !== null) {
          if (typeof this.variable_set.client.id !== "undefined") {
            return this.variable_set.client.id;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      // setter
      set: function(newValue) {
        this.formData.client = { id: newValue };
        this.variable_set.client = { id: newValue };
      }
    },
    project_id: {
      // this feels wrong, but it works...
      // getter
      get: function() {
        if (this.variable_set.project != null) {
          if (typeof this.variable_set.project.id !== "undefined") {
            return this.variable_set.project.id;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      // setter
      set: function(newValue) {
        this.formData.project = { id: newValue };
        this.variable_set.project = { id: newValue };
      }
    }
  },
  methods: {
    updateVariableSet() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        } else {
          this.mutation = require("@/graphql/variables/updateVariableSet.gql");
          this.saveForm();
        }
      });
    },
    createVariableSet() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        } else {
          this.mutation = require("@/graphql/variables/createVariableSet.gql");
          this.saveForm();
        }
      });
    },
    saveForm() {
      if (this.formData.client == null) {
        this.formData.client = {};
      }
      if (this.formData.project == null) {
        this.formData.project = {};
      }
      this.formLoading = true;
      this.$apollo
        .mutate({
          // Query
          mutation: this.mutation,
          // Parameters
          variables: {
            id: this.formData.id,
            name: this.formData.name,
            description: this.formData.description,
            instructions: this.formData.instructions,
            client: this.formData.client.id,
            project: this.formData.project.id
          }
        })
        .then(data => {
          // report success
          this.$emit("variable-set-form-closed", data);
          this.$emit("variable-set-form-saved", data);
          this.formLoading = false;
        })
        .catch(error => {
          // Error
          console.error(error);
          // update user....
          this.formLoading = false;
        });
    }
  },
  watch: {
    /*
    variable_set: {
      handler: function() {
        this.formData = { ...this.defaultForm, ...this.variable };
      },
      deep: true
    }

     */
  }
};
</script>
